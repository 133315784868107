import type { ISharedKitButtonColorPreset } from '~/components/SharedKitButton/SharedKitButton.types';
import { Colors } from '~/constants/colors.constants';
import { PaymentColors } from '~/features/payment/constants/colors.constants';

type TColorPresetsKeys = 'SUM_PRESETS' | 'BLOGGER_UPGRADE' | 'BLOGGER_UPGRADE_ACTIVE' | 'PAY_BLOGGER';

export const PaymentButtonPresets: Record<TColorPresetsKeys, ISharedKitButtonColorPreset> = {
  BLOGGER_UPGRADE: {
    defaultBackground: Colors.MAIN.PURPLE_200,
    hoverBackground: Colors.MAIN.PURPLE_200,
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
  BLOGGER_UPGRADE_ACTIVE: {
    defaultBackground: Colors.ADDITIONAL.TRANSPARENT,
    hoverBackground: Colors.ADDITIONAL.TRANSPARENT,
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
  PAY_BLOGGER: {
    defaultBackground: {
      colorStops: PaymentColors.PAYMENT_GRADIENT_LIGHTEN,
      toDirection: '140deg',
    },
    hoverBackground: {
      colorStops: PaymentColors.PAYMENT_GRADIENT_LIGHTEN_REVERSED,
      toDirection: '140deg',
    },
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
  SUM_PRESETS: {
    defaultBackground: Colors.ADDITIONAL.TRANSPARENT,
    hoverBackground: Colors.MAIN.PURPLE_200,
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
};
