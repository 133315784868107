<template>
  <section class="instant-controller">
    <div class="instant-controller__content">
      <h2 class="instant-controller__content-title">{{ $t('PaymentPage.wannaPay') }}</h2>
      <PaymentInputField
        v-model:value="promoInfo.input"
        class="instant-controller__content-input"
        :placeholder="$t('PaymentPage.promocode')"
        :is-succeed="promoInfo.isSucceed"
        :error="promoInfo.error"
      />
      <span class="instant-controller__content-info">
        {{ $t('PaymentPage.enteredPromo') }}
      </span>
    </div>
    <div class="instant-controller__bonus">
      <PaymentBonusCard :amount="instantPromo" is-ruble without-plus />
    </div>
  </section>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { usePromoStore } from '~/features/payment/store/promo.store';

const promoStore = usePromoStore();
const { promoInfo, instantPromo } = storeToRefs(promoStore);
</script>

<style scoped lang="scss" src="./InstantController.scss" />
