<template>
  <section class="methods-controller">
    <LazyPaymentMethodButton
      v-for="method in methods"
      :key="method.id"
      :icon="method.icon"
      :is-active="isActiveMethod(method.id)"
      @click="handleSelectMethod(method)"
    />
  </section>
</template>

<script setup lang="ts">
import { useMethodsStore } from '~/features/payment/store/methods.store';
import { useSumStore } from '~/features/payment/store/sum.store';
import { ECountryMethodType, type ICountryMethod } from '~/features/payment/types/country/client.types';

const changeMD = GlobalUtils.Media.changeByMedia('md');

const methodsStore = useMethodsStore();
const { methods, selectedMethod } = storeToRefs(methodsStore);

const sumStore = useSumStore();
const { sum } = storeToRefs(sumStore);

const isActiveMethod = (id: number) => selectedMethod.value?.id === id;

const handleSelectMethod = (method: ICountryMethod) => {
  methodsStore.selectMethod(method);

  if (!window || method.type === ECountryMethodType.SKIN || sum.value >= method.min || changeMD(true, false)) return;
  window.scrollTo({ behavior: 'smooth', top: 0 });
};
</script>

<style scoped lang="scss" src="./MethodsController.scss" />
