<template>
  <div class="main-page">
    <PaymentTitleControllerSkeleton />
    <div class="main-page__content">
      <PaymentRightSideControllerSkeleton />
      <PaymentLeftSideControllerSkeleton />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCountriesStore } from '~/features/payment/store/countries.store';
import { useBonusesStore } from '~/features/payment/store/bonuses.store';
import { usePromoStore } from '~/features/payment/store/promo.store';

import auth from '~/features/payment/middleware/auth';
import { useCountryStore } from '~/features/payment/store/country.store';

import { SAVED_PROMO_KEY } from '~/features/payment/constants/keys.constants';

definePageMeta({
  layout: 'payment',
  middleware: auth,
});

const countriesStore = useCountriesStore();
const bonusesStore = useBonusesStore();

const promoStore = usePromoStore();
const { promoInfo } = storeToRefs(promoStore);

const countryStore = useCountryStore();

const savedPromo = usePersistedRef(SAVED_PROMO_KEY, '');

const { queryValue } = useUTM();

if (queryValue.value.utm_content) {
  promoInfo.value.input = `${queryValue.value.utm_content}`;
}

await useAsyncData(
  'payment-base-data',
  () => {
    const promises = [countriesStore.getCountries(), bonusesStore.getBonuses(), countryStore.getCountry('default')];
    return Promise.allSettled(promises);
  },
  {
    server: false,
    lazy: true,
  },
);

onBeforeMount(() => {
  if (!savedPromo.value || queryValue.value.utm_content) return;
  promoInfo.value.input = savedPromo.value;
});

watch(
  promoInfo,
  (value) => {
    savedPromo.value = !value.input || !value.isSucceed ? '' : value.input;
  },
  {
    deep: true,
  },
);
</script>

<style src="./MainPage.scss" scoped lang="scss"></style>
