<template>
  <section class="cash-preview-controller">
    <h2 class="cash-preview-controller__title">{{ $t('PaymentPage.get') }}</h2>
    <PaymentFieldWithSelect
      class="cash-preview-controller__result"
      :value="willHaveSum"
      :currency-symbol="getIsFake ? Currency.DIAMOND : Currency[defaultCurrency as TCurrencyType]"
      is-disabled
    />
    <div v-if="!bloggerPromo" class="cash-preview-controller__bonuses">
      <PaymentBonusCard
        v-if="shownBonusCard.percent"
        :is-company-bonus="shownBonusCard.isCompany"
        :amount="shownBonusCard.percent"
      />
      <LazyPaymentBonusUpgrade :text="offerText" :is-active="!activeAndNextBonuses.next" @click="handleUpgrade" />
    </div>
    <div v-if="activeBloggerBonus" class="cash-preview-controller__blogger-reward">
      <LazyPaymentBloggerResultReward
        v-if="activeBloggerBonus.percent"
        :image="percentImage"
        :subtitle="$t('PaymentPage.toPay')"
      >
        <template #title>
          +{{ activeBloggerBonus.percent }}
          <SvgoPaymentBonusPercent :width="bloggeResultPercentSize" :height="bloggeResultPercentSize" />
        </template>
      </LazyPaymentBloggerResultReward>

      <LazyPaymentBloggerResultReward v-if="activeBloggerBonus.coins" :image="coinIcon" subtitle="Tasty Coins">
        <template #title> {{ activeBloggerCoins }}</template>
      </LazyPaymentBloggerResultReward>

      <LazyPaymentBloggerResultReward
        v-if="activeBloggerBonus.case"
        :image="activeBloggerBonus.case.image"
        :subtitle="activeBloggerBonus.case.name"
        :badge-price="activeBloggerBonus.case.price"
      >
        <template #title> CASE</template>
      </LazyPaymentBloggerResultReward>
    </div>
  </section>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useCurrencyStore } from '~/features/payment/store/currency.store';
import { useSumStore } from '~/features/payment/store/sum.store';
import { useBonusesStore } from '~/features/payment/store/bonuses.store';
import { usePromoStore } from '~/features/payment/store/promo.store';
import { useEventStore } from '~/store/event/event.store';
import { useUserStore } from '~/store/user/user.store';

import percentImage from '~/assets/images/Payment/percent.avif';

import { Currency } from '~/constants/currency.constants';
import { PaymentEvents } from '~/repository/amplitude/events/payment';
import type { TCurrencyType } from '~/types/Shared.types';

const {
  $projectConfig: { defaultCurrency },
} = useNuxtApp();

const { t } = useI18n();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const currencyStore = useCurrencyStore();
const { currency } = storeToRefs(currencyStore);

const sumStore = useSumStore();
const { sumField, sumWithRate } = storeToRefs(sumStore);

const bonusesStore = useBonusesStore();
const { activeAndNextBonuses, shownBonusCard } = storeToRefs(bonusesStore);

const eventStore = useEventStore();
const { coinIcon } = storeToRefs(eventStore);

const promoStore = usePromoStore();
const { bloggerPromo, activeBloggerBonus } = storeToRefs(promoStore);

const userStore = useUserStore();
const { getIsFake } = storeToRefs(userStore);

const bloggeResultPercentSize = computed(() => changeXL(32, 20));

const offerText = computed(() => {
  const nextBonus = activeAndNextBonuses.value.next;

  if (!nextBonus) return t('PaymentPage.maxBonusText');

  const currencySymbol = getIsFake.value ? Currency.DIAMOND : Currency[defaultCurrency as TCurrencyType];
  return t('PaymentPage.increaseSum', { currency: currencySymbol, from: nextBonus.from, percent: nextBonus.percent });
});

const willHaveSum = computed(() => {
  if (!sumWithRate.value || !currency.value) return '0';

  const activeBonusPercent = activeBloggerBonus?.value?.percent ?? shownBonusCard.value.percent;
  const activeBonusMultiplicator = 1 + activeBonusPercent / 100;

  const notRoundedResult = sumWithRate.value * activeBonusMultiplicator;

  return GlobalUtils.Prices.toFixedDefault(notRoundedResult);
});

const activeBloggerCoins = computed(() => {
  const multiplier = activeBloggerBonus?.value?.coins ?? 0;
  return Math.trunc(sumWithRate.value * (multiplier / 100));
});

const handleUpgrade = () => {
  const nextBonus = activeAndNextBonuses.value.next;
  if (!nextBonus) return;

  PaymentEvents.dealClicked();

  const nextBonusWithRate = Math.ceil(nextBonus.from * (currency.value?.rate ?? 1));
  sumField.value.value = `${nextBonusWithRate}`;
};
</script>

<style scoped lang="scss" src="./CashPreviewController.scss" />
